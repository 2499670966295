<template>
    <section class="login">
        <vue-snotify></vue-snotify>
        <div class="container-scroller">
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-center auth">
                    <div class="row w-100 flex-grow">
                        <div class="col-xl-4 col-lg-6 mx-auto">
                            <div class="auth-form-light text-left p-5">
                                <div class="brand-logo text-center">
                                    <img src="../assets/images/watch-logo.png"/>
                                </div>
                                <form class="pt-3">
                                    <b-form-group
                                            id="input-group-1"
                                            label="Email Address"
                                            label-for="input-1"
                                    >
                                        <b-form-input
                                                id="input-1"
                                                type="email"
                                                placeholder="Email Address"
                                                v-model="$v.email.$model"
                                                :state="$v.email.$dirty ? !$v.email.$error : null"
                                                aria-describedby="input-2-live-feedback"
                                        ></b-form-input>
                                        <b-form-invalid-feedback v-if="!$v.email.required"
                                                                 id="input-2-live-feedback">{{ $t("field-required") }}
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback v-if="!$v.email.email" id="input-2-live-feedback">
                                            Invalid Email.
                                        </b-form-invalid-feedback>

                                    </b-form-group>
                                    <b-form-group
                                            id="input-group-2"
                                            label="Password"
                                            label-for="input-2"
                                    >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-2"
                                                :type="inputType"
                                                placeholder="Password"
                                                v-model="$v.password.$model"
                                                :state="$v.password.$dirty ? !$v.password.$error : null"
                                                aria-describedby="input-3-live-feedback"
                                        ></b-form-input>
                                         <b-form-invalid-feedback v-if="!$v.password.required"
                                                                  id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="!$v.password.minLength"
                                                                  id="input-3-live-feedback">Password must have at least {{ $v.password.$params.minLength.min }} letters.</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="password && !$v.password.valid"
                                                                  id="input-3-live-feedback">{{ $t("Password contains atleast One Uppercase, One Lowercase, One Number and One Special Character.") }}</b-form-invalid-feedback>
                                      <i @click="toggleShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword, 'fa fa-eye-slash': !showPassword, }"></i></span>

                                    </b-form-group>
                                    <b-form-group
                                            id="input-group-4"
                                            label="Confirm Password"
                                            label-for="input-4"
                                    >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-4"
                                                :type="inputType1"
                                                placeholder="Confirm Password"
                                                v-model="$v.password_confirmation.$model"
                                                :state="$v.password_confirmation.$dirty ? !$v.password_confirmation.$error : null"
                                                aria-describedby="input-4-live-feedback"
                                        ></b-form-input>
                                              <b-form-invalid-feedback v-if="!$v.password_confirmation.required"
                                                                       id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="!$v.password_confirmation.sameAsPassword"
                                                                  id="input-4-live-feedback">Passwords must be identical.</b-form-invalid-feedback>
                                      <i @click="toggleConfirmShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword1, 'fa fa-eye-slash': !showPassword1, }"></i></span>

                                    </b-form-group>
                                    <div class="mt-3">
                                        <button type="button" class="btn btn-dark btn-lg btn-block"
                                                :disabled="this.$v.email.$anyError || this.$v.password.$anyError || this.isDisabled"
                                                @click="login()">
                                            SIGN IN
                                        </button>
                                    </div>
                                    <div class="mt-3">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <!--                                                <b-form-checkbox v-model="remember_be" value="true">Remember me-->
                                                <!--                                                </b-form-checkbox>-->
                                            </div>
                                            <div class="col-md-6">
                                                <router-link class="ml-5 auth-link float-right text-info "
                                                             to="/login">
                                                    Login
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- content-wrapper ends -->
            </div>
            <Footer/>
            <!-- page-body-wrapper ends -->
        </div>
    </section>
</template>


<script>
    import router from "../router"
    import API from '@/api'
    import Footer from "../layout/partials/Footer";
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, sameAs} from "vuelidate/lib/validators";
    import Vue from "vue";
    import Snotify, {SnotifyPosition} from "vue-snotify";
    const options = {
        toast: {
            timeout: 2000,
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        name: "Reset-Password",
        mixins: [validationMixin],
        components: {
            Footer
        },
        data() {
            return {
                email: '',
                password: '',
                password_confirmation: '',
                disabled: '',
                remember_be: '',
                token: '',
                showPassword: true,
                showPassword1: true,
                isDisabled: false,
                inputType: 'password',
                inputType1: 'password',
            };
        }, validations: {
            email: {
                email,
                required,
            }, password: {
                required,
                minLength: minLength(6),
                valid: function (value) {
                    const containsUppercase = /[A-Z]/.test(value)
                    const containsLowercase = /[a-z]/.test(value)
                    const containsNumber = /[0-9]/.test(value)
                    const containsSpecial = /[#?!@$%^&*-]/.test(value)
                    return containsUppercase && containsLowercase && containsNumber && containsSpecial
                },
            }, password_confirmation: {
                required,
                sameAsPassword: sameAs('password')
            }
        },

        methods: {
            login() {
                this.isDisabled = true;
                this.$v.email.$touch();
                this.$v.password.$touch();
                if (this.$v.email.$anyError || this.$v.password.$anyError || this.$v.password_confirmation.$anyError) {
                    return;
                }
                let email = this.email
                let password = this.password
                let password_confirmation = this.password_confirmation
                let token = this.token
                let data = {
                    email: email,
                    password: password,
                    password_confirmation: password_confirmation,
                    token: token
                }

                API.resetPassword(
                    data,
                    data => {
                        this.isDisabled = false;
                        if (data.status == 200) {
                            router.push("/");
                        } else {
                            console.log(data.message)
                            var msg= data.message;
                            this.$snotify.error(msg, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    },
                    err => {
                        this.isDisabled = false;
                        console.log(err);
                    }
                )
            },
            toggleShow() {
                this.showPassword = !this.showPassword;
                if (this.showPassword) {
                    this.inputType = 'password';
                } else {
                    this.inputType = 'text';
                }
            }, toggleConfirmShow() {
                this.showPassword1 = !this.showPassword1;
                if (this.showPassword1) {
                    this.inputType1 = 'password';
                } else {
                    this.inputType1 = 'text';
                }
            }
        },
        mounted() {
            this.token = this.$route.params.token;
        }
    }
</script>
